<template>
    <div>
  <div v-if="bMiniVariant"  >
    <div
    class="v-container-menu" 
    style="
      position: absolute;
      z-index: 9;
      width: 300px;
      
    ">
    <!-- logo section -->
    <div class="content-image">
      <img
       :style="'background: url(/static/Imagen3.png) center center / cover'"
        color="#ffffff"
      >
    </div>
      <!-- options menu section -->
      <div
        class="container-options-menu overflow-y-auto "
        
        :style="{'height': menuHeight + 'px'}"
      >
          <v-list
            class="content-options-menu"
            color="transparent"
          >
            <!-- Order
            <v-list-item
              @click="$store.commit('setMenuSidebar', false)"
              to="/admin/orders"
              exact
              :style="(this.$route.name == 'Orders') ? { backgroundColor: '#283C4D', borderRadius: '13px' } : ((this.$route.name == 'OrderDetail') ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {})"
            >
              <div :class="(this.$route.name == 'Orders') ? 'active-menu' : ((this.$route.name == 'OrderDetail') ? 'active-menu' : '')" />
              <v-list-item-icon>
                <img
                src="@/assets/icons/Menu/Icon-order-resp.svg"/>
              </v-list-item-icon>
              <v-list-item-title class="mtserr">{{texts.menu.title1}}</v-list-item-title>
            </v-list-item> -->

       <v-list-item
        width="100%"
        tile
        v-for="item in filteredItems"
        :key="item.title"
        :to="item.href"
        :active-class="'ItemActiveClass'"
        link
      >
        <!-- Start list item icon -->
        <v-list-item-icon>
          <v-icon
            :style="
              item.href == $route.fullPath
                ? 'color: black !important'
                : 'color: black !important'
            "
            dark
          >
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <!-- End list item icon -->
        <!-- Start list item text -->
        <v-list-item-content>
          <v-list-item-title
            :style="
              item.href == $route.fullPath
                ? 'color: black !important'
                : 'color: black !important'
            "
            class="SideMenuItemText"
          >
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
        <!-- End list item text -->
      </v-list-item>
          </v-list>
    </div>

       <!-- close sesion -->
    <div>
  

    <v-list-item @click="logout()" link>
          <!-- Start list item icon -->
          <v-list-item-icon>
            <v-icon
              style="color: black !important; margin-left: -2px !important"
              dark
            >
              {{ texts.logout.icon }}
            </v-icon>
          </v-list-item-icon>
          <!-- End list item icon -->
          <!-- Start list item text -->
          <v-list-item-content>
            <v-list-item-title
              style="color: black !important"
              class="SideMenuItemText"
            >
              {{ texts.logout.text }}
            </v-list-item-title>
          </v-list-item-content>
          <!-- End list item text -->
        </v-list-item>
    </div>
  </div>
  </div>

  <!-- style of menu close -->
  <div v-else >
    <div
    class="v-container-menu responsive-design"
    >
    <!-- logo section -->
    <div class="content-image-responsive">
      <!-- <img
      src="@/assets/icons/Menu/Icon-proplat-resp.svg"
    ></img> -->
    </div>
    <!-- options menu section -->
    <div
    class="container-options-menu overflow-y-auto "        
    :style="{'height': menuHeightResp + 'px'}">
    <v-list
    class="content-options-menu"
    color="transparent"
  >
    <!-- Order
    <v-list-item
      @click="$store.commit('setMenuSidebar', false)"
      to="/admin/orders"
      exact
      class="content-list-item"
      :style="(this.$route.name == 'Orders') ? { backgroundColor: '#283C4D', borderRadius: '13px' } : ((this.$route.name == 'OrderDetail') ? { backgroundColor: '#283C4D', borderRadius: '13px' } : {})"
      ref="OrderList"
    >
      <div :class="(this.$route.name == 'Orders') ? 'active-menu' : ((this.$route.name == 'OrderDetail') ? 'active-menu' : '')" />
      <v-list-item-icon>
      
      </v-list-item-icon>
    </v-list-item> -->

        <v-list-item v-for="(item, i) in items" :key="i" :to="item.href">
          <!-- Start item icon -->
          <v-icon style="font-size: 14px; margin-right: 10px"
            >{{ item.icon }}
          </v-icon>
          <!-- End item icon -->
          <!-- Start item text -->
          <v-list-item-title
            v-text="item.title"
            :style="'color:white; font-size: 14px'"
          />
          <!-- Ed item text -->
        </v-list-item>    



  </v-list>
  </div>

        <!-- close sesion -->
    <div class="">
      <!-- <v-btn
      class="button-session mon-regular"
      @click="killSession"
    >
   
      <img  width="20px" class="img-menu-responsive" style="margin-left: -2px;" src="@/assets/icons/Menu/log-out.svg" alt="" />
    </v-btn> -->
       <v-list-item @click="logout">
          <!-- Start logout option -->
          <v-list-item-title
            v-text="'Sign Out'"
            :style="'color:white; font-size: 14px'"
          />
          <!-- End logout option -->
          <!-- Start option icon -->
          <v-icon style="font-size: 14px; margin-right: 10px">
            mdi-logout
          </v-icon>
          <!-- End option icon-->
        </v-list-item>    
    </div>
  </div>
  </div>

</div>

</template>

<script>
export default {
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.menuHeight = window.innerHeight  - 316;
      this.menuHeightResp = window.innerHeight  - 265;
    },
    logout() {
      this.$store
        .dispatch("logout")
        .then((resp) => {
          this.$store.commit("toggle_alert", resp);
          this.$router.go();
        })
        .catch((err) => {
          this.$store.commit("toggle_alert", err);
        });
    },
  },
  computed: {
    filteredItems: function () {
      return this.texts.items.filter((routeOption) => {
        if (this.userPermissions.includes("Administrator", 0)) {
          return routeOption;
        } else {
          var exp = this.userPermissions;
          exp = this.userPermissions.replace(/[\[\]']/g, "");
          exp = exp.replace(/"/g, "");
          let arr = "";
          exp.split(",").forEach((e) => {
            if (e == routeOption.permission) {
              arr = e;
            }
          });

          return arr;
        }
      });
    },
    userPermissions() {
      return this.$store.state.permissions;
    },
    bMiniVariant() {
      return this.$store.state.bMiniVariant;
    },
    // return texts for this section
    texts() {
      return texts.spanish.admin.sideMenu;
    },
  },
  data: () => ({
    // image source for login
    sLogoSource: "/static/Control3_Logo.png",
    menuHeight:0,
    menuHeightResp:0
  }),
};
</script>

<style>
.sideMenuDivider {
  border-color: #33958d50 !important;
}
.AdminNavDrawer .v-list--nav {
  padding-right: 0px !important;
}
.AdminNavDrawer {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
</style>

<style>
.v-container-text-menu p {
  color: #d6d6d6;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 14px;
  padding-left: 28px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.v-container-orders {
  margin-top: -10px;
}

.v-container-leads, .v-container-productos, .v-container-contactos {
  margin-top: 5px;
}

.v-separation-text-menu {
  color: #c7cfd6;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

.v-container-menu {
  position: absolute;
  z-index: 9;
}

.v-button-menu {
  color: #ffffff;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  width: 100%;
  height: 45px !important;
  text-transform: capitalize;
}

.v-button-menu p {
  width: 100%;
  margin-bottom: 0;
}

.v-btn:not(.v-btn--round).v-size--default {
  padding: 0px;
}

.v-icons {
  color: #ffffff !important;
  font-size: 16px !important;
  margin-right: 10px;
  margin-top: 0px;
}

/* repsonsive design style */

.responsive-design .v-btn:not(.v-btn--round).v-size--default {
  min-width: 100%;
  height: 50px !important;
}



.responsive-design .v-icons {
  color: #ffffff !important;
  font-size: 16px !important;
  margin-right: 0px;
}
.content-options-menu {
  padding: 25px;
}



.content-options-menu
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  background-color: #17283b;
  cursor: pointer;
}

.content-options-menu .v-icon.v-icon,
.content-options-menu .v-list-item__title,
.content-options-menu .mdi:before,
.content-options-menu .mdi-set,
.content-options-menu
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #ffffff !important;
}

.content-options-menu .v-list-item--active {
  color: #ffffff !important;
  background: #283c4d 0% 0% no-repeat padding-box;
  border-radius: 13px;
}

.content-options-menu .v-list-item--active::before {
  border-radius: 13px;
}

.content-options-menu .v-list-item__icon {
  margin-right: 20px !important;
}

.container-options-menu,
.container-languages {
  border-bottom: 2px solid #283c4d;
}

/* .container-options-menu {
  overflow: scroll;
} */

.icon-menu-responsive{
  margin-left: -10px;
}

.img-menu-responsive{
  margin-left: -12px;
}

.content-list-item{
  text-align: center !important;
  width: 60px !important;
  margin-left: -10px;
}
.content-image {
  /* width: 100%; */
  height: 129px;
  padding: 50px ;
  border-bottom: 2px solid #283c4d;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.content-image-responsive {
  /* width: 100%; */
  height: 70px;
  padding: 20px;
  margin-left: 10px;
  border-bottom: 2px solid #283c4d;
}

.content-image-responsive img {
  /* width: 100%; */
  height: auto;
}

@supports (object-fit: cover) {
  .content-image-responsive img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.active-menu {
  background: transparent linear-gradient(190deg, #6FD4FF 0%, #3194FF 100%) 0%
    0% no-repeat padding-box !important;
  width: 8px !important;
  height: 50px !important;
  position: absolute !important;
  left: -15px !important;
  border-top-right-radius: 11px !important;
  border-bottom-right-radius: 11px !important;
}


.active-menu-1 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-menu-2 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 115px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-menu-3 {
  background: transparent linear-gradient(190deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  width: 11px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 170px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.button-session {
  background-color: transparent !important;
  height: 70px !important;
  width: 100% !important;
  border-radius: 0px;
  color: #ffffff !important;
  font-size: 16px;
  text-transform: initial;
  justify-content: flex-start;
  padding-left: 40px !important;
}


.content-options-menu {
  padding: 25px;
}

.content-options-menu
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  background-color: #17283b;
  
  cursor: pointer;
}

.content-options-menu .v-icon.v-icon,
.content-options-menu .v-list-item__title,
.content-options-menu .mdi:before,
.content-options-menu .mdi-set,
.content-options-menu
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #ffffff !important;
}



.content-options-menu .v-list-item--active {
  color: #ffffff !important;
  background: #283c4d 0% 0% no-repeat padding-box;
  border-radius: 13px;
}

.content-options-menu .v-list-item--active::before {
  border-radius: 13px;
}

.content-options-menu .v-list-item__icon {
  margin-right: 20px !important;
}

.container-options-menu,
.container-languages {
  border-bottom: 2px solid #283c4d;
}

/* .container-options-menu {
  overflow: scroll;
} */



.button-close-menu {
  min-width: 39px !important;
  height: 39px !important;
  background-color: transparent !important;
  opacity: 1;
  border: 1px solid #ffffff;
  margin-left: 15px;
  margin-top: 20px;
  padding: 0px !important;
}

.container-menu-left {
  /* background: #151d27 0% 0% no-repeat padding-box; */
  width: 349px;
  height: 100%;
  opacity: 1;
}

.container-menu-right {
  background-color: #000000;
  width: calc(100% - 349px);
  height: 100%;
  opacity: 0.9;
}

.content-fade {
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 100vh;
  display: flex;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media (max-width: 425px) {
  .container-menu-left {
    width: 260px;
  }

  .container-menu-right {
    width: calc(100% - 260px);
  }
}


/* STYLE SCROLL */

/* width */
::-webkit-scrollbar {
  width: 1px !important;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #151D27 !important;  */
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #151D27 !important;  */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #151D27 !important;  */
}


</style>

<style 
  scoped>
/** style for division between logo and items  */
/** style for menu item text */
.SideMenuItemText {
  font-family: "Poppins", sans-serif !important;
  color: black;
  font-size: 13px !important;
  font-weight: 600 !important;
}
/** change color when route active */
.ItemActiveClass {
  border-radius: 0;
  color: #ffce00;
  background-color: #ffce00 !important;
  padding-left: 35px;
}

.v-list--nav .v-list-item {
  padding-left: 35px;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}

.v-navigation-drawer--mini-variant .ItemActiveClass {
  padding-left: 10px;
}

.v-navigation-drawer--mini-variant .v-list--nav .v-list-item {
  padding-left: 0px;
}

.v-navigation-drawer--mini-variant .v-navigation-drawer__append {
  padding-left: -10px;
}

.v-navigation-drawer--mini-variant .icon-logout {
  padding-left: 0px !important;
}

.v-navigation-drawer--mini-variant .ItemActiveClass {
  margin-left: -12px;
  padding-left: 12px !important;
}

.v-navigation-drawer--mini-variant .icon-logout .v-list-item {
  padding-left: 15px;
}

.icon-logout {
  padding-left: 0px !important;
}

.icon-logout .v-list-item {
  padding-left: 40px;
}
</style>